<template>
  <div class="list-group personal-list-group">
    <p v-if="items.length == 0" class="text-center">
      Aún se han cargado documentos
    </p>
    <template v-else>
      <div class="container-list-video" v-for="i in items" :key="i.key">
        <div class="container-symbol-video">
          <a
          target="_blank"
          :href="i.url"
          > 
            <svg 
            version="1.1"
            class="symbol-video"
            xmlns="http://www.w3.org/2000/svg" 
            xmlns:xlink="http://www.w3.org/1999/xlink" 
            x="0px" y="0px"
            viewBox="0 0 97.75 97.75"
            xml:space="preserve"
            >
              <g>
                <path d="M39.969,59.587c7.334-3.803,14.604-7.571,21.941-11.376c-7.359-3.84-14.627-7.63-21.941-11.447
                  C39.969,44.398,39.969,51.954,39.969,59.587z"/>
                <path d="M48.875,0C21.883,0,0,21.882,0,48.875S21.883,97.75,48.875,97.75S97.75,75.868,97.75,48.875S75.867,0,48.875,0z
                    M82.176,65.189c-0.846,3.67-3.848,6.377-7.461,6.78c-8.557,0.957-17.217,0.962-25.842,0.957c-8.625,0.005-17.287,0-25.846-0.957
                  c-3.613-0.403-6.613-3.11-7.457-6.78c-1.203-5.228-1.203-10.933-1.203-16.314s0.014-11.088,1.217-16.314
                  c0.844-3.67,3.844-6.378,7.457-6.782c8.559-0.956,17.221-0.961,25.846-0.956c8.623-0.005,17.285,0,25.841,0.956
                  c3.615,0.404,6.617,3.111,7.461,6.782c1.203,5.227,1.193,10.933,1.193,16.314S83.379,59.962,82.176,65.189z"/>
              </g>
            </svg>
          </a>
        </div>
        
        <div class="desc-video">
          <div class="container-fecha-sesion">
            <div class="container-fecha">
              <p class="fecha">{{ i.fecha }} </p>
              <span class="separador">|</span>
              <p class="hora">{{ i.hora }} hrs.</p>
            </div>
            <template v-if="i.sesion">
              <div class="container-sesion">
                <span class="sesion">
                  <a :href="`https://congresoqroo.gob.mx/ordenesdia/${i.sesion}`" target="_blank">
                    Ir a la Sesión
                  </a>
                </span>
              </div>
            </template>
          </div>
          <p>{{ i.titulo }}</p>
        </div>
      </div>
    </template>
    
    
  </div>
</template>

<script>
import firebase from "../firebase";

export default {
  props: ["docto"],
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.docto)
        .collection("documentos"),
      items: {},
    };
  },
  created() {
    this.ref.orderBy("fecha", "desc").onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          titulo: doc.data().titulo,
          fecha: doc.data().fecha,
          hora: doc.data().hora,
          sesion: doc.data().sesion,
          url: doc.data().url,
        });
      });
      //console.log(this.items)
    });
  },
};
</script>

<style lang="scss" scope>
@import "../scss/abstracts/variables";
.personal-list-group {
  border-radius: 0;

}

.container-list-video {
  display: flex;
  padding: 0.5rem;

  .container-symbol-video {
    padding: 0.5rem;

    .symbol-video {
      width: 2.5rem;
      height: auto;

      &:hover {
        fill: #9d8877;
      }
    }
  }
  .desc-video {
    padding: 0.5rem;
    .container-fecha-sesion {
      display: flex;
      .container-fecha {
        font-size: 0.8rem;
        @include media-breakpoint-up(md) {
          display: flex;
        }
        p {
          margin-bottom: 0;
          line-height: 1.2;
        }
        .fecha {
          font-weight: 700;
          color: $color-bg-01;
        }
        .separador {
          display: none;
          @include media-breakpoint-up(md) {
            display: block;
            line-height: 1.2;
            margin: 0 0.25rem;
          }
        }
        .hora {
          font-weight: 400;
        }
      }
      .container-sesion {
        padding: 0 1rem;
        line-height: 1.2;
        @include media-breakpoint-up(md) {
          padding: 0 1.5rem;
        }

        .sesion {
          font-size: 0.7rem;
          font-weight: 700;
          a {
            background: $color-bg-03;
            border-radius: 10px;
            color: $color-text;
            text-decoration: none;
            padding: 0 1rem;
            &:hover {
              background: $color-text;
              color: $color-bg-03
            }
          }
        }
      }
    }
  }

}
</style>
