<template>
  <div class="container container-secciones">
    <img class="logo-seccion" :src="seccion.logo" alt="" />
    <h1 class="titulo-seccion">{{ seccion.name }}</h1>
    <p class="descripcion">{{ seccion.description }}</p>

    <template>
      <PubListDocuments :docto="this.$route.params.id" />
    </template>
  </div>
</template>

<script>
import firebase from "../firebase";
import PubListDocuments from "../components/PubListDocuments";

export default {
  name: "Seccion",
  components: {
    PubListDocuments,
  },
  data() {
    return {
      key: "",
      seccion: {},
    };
  },
  created() {
    const ref = firebase
      .firestore()
      .collection("secciones")
      .doc(this.$route.params.id);
    ref.get().then((doc) => {
      if (doc.exists) {
        this.key = doc.id;
        this.seccion = doc.data();
      } else {
        alert("No existe la sección!");
      }
    });
  },
};
</script>

<style lang="scss" scope>
@import "../scss/abstracts/variables";

.container-secciones {
  padding-top: 3rem;
}

.logo-seccion {
  width: 100px;
  display: block;
  margin: auto;
}
.titulo-seccion {
  font-family: $font-01;
  font-size: 1.25rem;
  color: $color-bg-01;
  text-align: center;
  margin-bottom: 0.5rem;
  @include media-breakpoint-up(md) {
    font-size: 1.8rem;
  }
}
.descripcion {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 0.75rem;
  color: $color-text;
}
</style>
